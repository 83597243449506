//Swiper docs: https://swiperjs.com/swiper-api

import Swiper, { Pagination, A11y, Autoplay } from "swiper";

const baseSwiperConfig = {
	// configure Swiper to use modules
	modules: [Pagination, A11y, Autoplay],
	spaceBetween: 10,

	autoplay: {
		delay: 5000,
	},

	pagination: {
		el: ".slider-pagination",
		clickable: true,
		type: "bullets",
		bulletClass: "slider-indicator",
		bulletActiveClass: "active",
		renderBullet: function (index, className) {
			return `<button id="slider-tab-${index}" type="button" aria-disabled="${index === 0}" ${index === 0 ? 'disabled' : ''} aria-controls="slide-${index}" class="${className}" aria-labelledby="slide-${index}"></button>`;
		},
	},

	a11y: {
		enabled: true,
		containerMessage: 'Premier Collection Products from Palm Beach Tan',
		containerRoleDescriptionMessage: 'carousel',
		itemRoleDescriptionMessage: 'slide'
	},

	on: {
		slideChange: function () {
			handleSliderBulletAria(this);
		}
	},
};

function handleSliderBulletAria(swiperSlider) {
	swiperSlider.pagination.bullets.map((bullet, index) => {
		if(swiperSlider.activeIndex === index) {
			bullet.setAttribute('aria-disabled', true);
			bullet.disabled = true;
		} else {
			bullet.setAttribute('aria-disabled', false);
			bullet.disabled = false;
		}
	});
}

// init Swiper:
const swiperProduct = new Swiper(".product-slider", {
	...baseSwiperConfig,
});

// init Swiper used for the mobile app:
// Has slidersPerView and breakpoints added
const swiperProductMobileApp = new Swiper(".product-slider-mobile-app", {
	...baseSwiperConfig,
	slidesPerView: 1.2,
	breakpoints: {
		// md
		768: {
			slidesPerView: 1.6,
		},
		// lg
		1024: {
			slidesPerView: 2.4,
		},
	},
});

// init Swiper used for the wellness equipment:
const swiperEquipmentMobileApp = new Swiper(".equipment-slider-mobile-app", {
	...baseSwiperConfig,
	slidesPerView: 1.5,
	breakpoints: {
		// md
		768: {
			slidesPerView: 1.8,
		},
		// lg
		1024: {
			slidesPerView: 2.5,
		},
	},

	// Overwrites
	a11y: {
		containerMessage: 'Wellness Equipment from Palm Beach Tan',
	},
});
